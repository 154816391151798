import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Header } from 'components/molecules/Header';
import VideoPlayer from 'components/molecules/VideoPlayer/VideoPlayer.js';
import { BannerText } from 'components/atoms/BannerText';
import { ArtistsList } from 'components/molecules/ArtistsList';
import { SkillSet } from 'components/molecules/SkillSet';
import { Discover } from 'components/molecules/Discover';
import { Search } from 'components/atoms/Search';
import { SlickSlider } from 'components/molecules/SlickSlider';
import { ArtistVideoCard } from 'components/atoms/ArtistVideoCard';
import { useDispatch } from 'react-redux';
import { artistVideoCardSliderConfig } from 'config/slider';
import { fetchHomeData } from 'state/common/home/thunk';
import { SliderArrow } from 'components/atoms/Icons/SliderArrow';
import { Link } from 'gatsby';
import { SIGNUP_SCREEN } from 'router/routes';
import { HomeType } from 'types/home';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useLocation } from '@reach/router';
import * as styles from './style.module.scss';
import Seo from '../../seo';
import { ArtistSlider } from '../../molecules/ArtistSlider';
import { FooterSm } from '../../molecules/FooterSm';
import { useAuth } from '../../../utils/hooks';
import { PersonalSection } from '../../molecules/PersonalSection';

type Props = {
	path?: string;
	homeServerData: HomeType;
};

export const Home: React.FC<Props> = (props) => {
	const { homeServerData } = props;
	const isLoggedInUser = useAuth();
	const { questions, skills, oneToOneArtists } = homeServerData;
	const dispatch = useDispatch();
	const { host } = useLocation();
	useEffect(() => {
		dispatch(fetchHomeData(homeServerData));
	}, [dispatch, homeServerData]);

	return (
		<>
			<Seo title="QUAN - Learn from the best musicians in the world · Quan" />
			<div className={styles.headerContainer}>
				<Header />
			</div>
			<div className={!isLoggedInUser ? styles.container : styles.container2}>
				<div className={styles.banner}>
					<div className={styles.banner_container}>
						<BannerText variant="light" />
					</div>
				</div>
			</div>
			<div>
				<div className={styles.searchContainer}>
					<hr />
					<Search />
				</div>
				<section className={styles.artistsSection}>
					<div className={styles.homeContainer}>
						<ArtistsList />
					</div>
				</section>
				<section className={styles.skillSetSection}>
					<ParallaxProvider>
						<SkillSet skills={skills} />
					</ParallaxProvider>
				</section>
				<section className={styles.discoverSection}>
					<div className={styles.homeContainer}>
						<Discover variant="yellow" />
						<div className={styles.artistSlider}>
							<SlickSlider
								settings={{
									...artistVideoCardSliderConfig,
									nextArrow: (
										<div>
											<SliderArrow className="oneToOne_next slickArrow" />
										</div>
									),
									prevArrow: (
										<div>
											<SliderArrow className="oneToOne_prev slickArrow" />
										</div>
									),
									arrows: true,
								}}
							>
								{questions?.length &&
									questions.map((question) => {
										return (
											<ArtistVideoCard key={question.id} question={question} className={styles.questionSliderItem} />
										);
									})}
							</SlickSlider>
						</div>
					</div>
				</section>
				<section className={styles.discoverSection2}>
					<div className={styles.homeContainer}>
						<Discover variant="coral" />
						<div className={styles.artistSlider}>{oneToOneArtists && <ArtistSlider artists={oneToOneArtists} />}</div>
					</div>
				</section>
				{!isLoggedInUser && <PersonalSection />}
				<section className={styles.videoSection}>
					<div className={styles.homeContainer}>
						<VideoPlayer
							videoUrl="https://quan-dev-processed.s3.amazonaws.com/static/homepage_video.m3u8"
							showBigFormatPreview
							controlsAtPreview
							wideAngle
							videoIsProcessed={false}
							fullscreen={false}
						/>
					</div>
				</section>
				<FooterSm />
			</div>
		</>
	);
};
