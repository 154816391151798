// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--neiQn";
export var bold = "style-module--bold--YBPck";
export var chromeframe = "style-module--chromeframe--dbenu";
export var errorText = "style-module--errorText--ENDj4";
export var light = "style-module--light--YalYZ";
export var overlay = "style-module--overlay--hUzNe";
export var text = "style-module--text--Di-5R";
export var textDefault = "style-module--text-default--93K9-";
export var textInput = "style-module--text-input--i9XOr";
export var videoJs = "style-module--video-js---4sgx";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--hkC8C";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--01PI7";
export var vjsPoster = "style-module--vjs-poster--GbEJx";