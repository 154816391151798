import React from 'react';
import Layout from 'components/layout';
import { Home } from 'components/organisms/Home';
import { HomeType } from '../types/home';

type Props = {
	pageContext: {
		serverData: HomeType;
	};
};

const Index = ({ pageContext }: Props) => {
	const { serverData } = pageContext;
	return (
		<Layout>
			<Home homeServerData={serverData ?? {}} />
		</Layout>
	);
};

export default Index;
