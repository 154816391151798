import React from 'react';
import * as styles from './style.module.scss';

type Props = {
	variant: 'light' | 'bold';
};

export const BannerText: React.FC<Props> = ({ variant }) => {
	return (
		<p className={`${styles[variant]}`}>
			Learn from the best <span>musicians</span> <br /> in the world.
		</p>
	);
};
